@font-face {
  font-weight: 400;
  font-family: "Quicksand";
  font-style: normal;
  src: url(/static/media/quicksand-latin-400.ad9a924a.woff2) format("woff2"), url(/static/media/quicksand-latin-400.2560b520.woff) format("woff");
  font-display: swap; }

@font-face {
  font-weight: 500;
  font-family: "Quicksand";
  font-style: normal;
  src: url(/static/media/quicksand-latin-500.c8bf53c3.woff2) format("woff2"), url(/static/media/quicksand-latin-500.1962271c.woff) format("woff");
  font-display: swap; }

@font-face {
  font-weight: 600;
  font-family: "Quicksand";
  font-style: normal;
  src: url(/static/media/quicksand-latin-600.43a7b9b8.woff2) format("woff2"), url(/static/media/quicksand-latin-600.d464ff72.woff) format("woff");
  font-display: swap; }

@font-face {
  font-weight: 700;
  font-family: "Quicksand";
  font-style: normal;
  src: url(/static/media/quicksand-latin-700.c370305c.woff2) format("woff2"), url(/static/media/quicksand-latin-700.a8112a66.woff) format("woff");
  font-display: swap; }

* {
  box-sizing: border-box; }

html {
  scroll-behavior: smooth;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0;
  color: #fff;
  font-weight: 400;
  font-size: 0.875rem;
  font-family: "Quicksand", Arial, sans-serif;
  line-height: 1.8;
  background-color: #0d0d0d; }
  @media (min-width: 900px) {
    body {
      font-size: 0.9375rem; } }

::-webkit-scrollbar {
  display: none; }
  @media (min-width: 900px) {
    ::-webkit-scrollbar {
      display: block;
      width: 0.5rem; } }
  ::-webkit-scrollbar-track {
    border-radius: 1.5rem; }
  ::-webkit-scrollbar-thumb {
    background: #2d8a25;
    border-radius: 0.5rem; }
    ::-webkit-scrollbar-thumb:hover {
      background: #2d8a25; }

::-webkit-selection {
  color: #000;
  background: #2d8a25; }

::selection {
  color: #000;
  background: #2d8a25; }

a,
.anchor {
  color: #2d8a25;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  transition: 500ms; }
  a:hover, a:active, a:focus,
  .anchor:hover,
  .anchor:active,
  .anchor:focus {
    color: #fff;
    outline: none; }

p {
  margin: 0 0 1.5rem; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 2rem 0;
  color: #fff;
  font-weight: 400;
  font-family: "Quicksand", Arial, sans-serif;
  line-height: 1.4; }

h1 {
  font-size: 2.25rem; }

h2 {
  font-size: 1.875rem; }

h3 {
  font-size: 1.5rem; }

h4 {
  font-size: 1.125rem; }

h5 {
  font-size: 0.875rem; }

h6 {
  font-size: 0.75rem; }

b,
strong {
  font-weight: bolder; }

hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border: 0;
  border-top: 1px solid rgba(45, 138, 37, 0.2); }

img {
  max-width: 100%;
  height: auto;
  border-style: none; }

address {
  margin-bottom: 1rem;
  font-style: normal; }

button,
input,
textarea {
  margin: 0;
  font-size: 100%;
  font-family: inherit;
  line-height: 1.15; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="submit"] {
  -webkit-appearance: button; }

textarea {
  overflow: auto; }

[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

#root {
  position: relative;
  width: 100%;
  overflow: hidden; }

.Main {
  display: block;
  float: right;
  width: 100%;
  transition: 500ms; }
  @media (min-width: 700px) {
    .Main {
      width: 70%; } }
  @media (min-width: 900px) {
    .Main {
      width: 75%; } }
  @media (min-width: 1200px) {
    .Main {
      width: 80%; } }
  @media (min-width: 1600px) {
    .Main {
      width: 85%; } }
  .Main-enter {
    transform: scale(1.1);
    opacity: 0; }
  .Main-enter-active {
    transform: scale(1);
    opacity: 1;
    transition: opacity 800ms, transform 800ms; }
  .Main-exit {
    transform: scale(1);
    opacity: 1; }
  .Main-exit-active {
    transform: scale(0.9);
    opacity: 0;
    transition: opacity 800ms, transform 800ms; }
  .offcanvas .Main {
    transform: translateX(270px); }

.About,
.Contact,
.Services,
.Stats,
.Testimonials {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  clear: both;
  width: 100%;
  padding: 5rem 1rem; }

@-webkit-keyframes fadeInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    opacity: 0; }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes fadeInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    opacity: 0; }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s; }

@-webkit-keyframes fadeInRight {
  from {
    transform: translate3d(100%, 0, 0);
    opacity: 0; }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes fadeInRight {
  from {
    transform: translate3d(100%, 0, 0);
    opacity: 0; }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
          animation-name: fadeInRight;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut; }

.Backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 990;
  width: 100%;
  height: 100vh;
  background: rgba(7, 7, 7, 0.9); }

.btn {
  padding: 0.5rem 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 1px;
  white-space: nowrap;
  text-align: center;
  border: 1px solid;
  border-radius: 2px;
  transition: 500ms;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: manipulation; }
  .btn:hover, .btn:active, .btn:focus {
    outline: none;
    box-shadow: none; }
  .btn-primary {
    color: #000;
    background-color: #2d8a25;
    border-color: #2d8a25; }
    .btn-primary:hover, .btn-primary:focus {
      color: #2d8a25;
      background-color: transparent;
      border-color: #2d8a25; }
  .btn-primary-outline {
    color: #2d8a25;
    background-color: transparent;
    border-color: #2d8a25; }
    .btn-primary-outline:hover, .btn-primary-outline:focus {
      color: #000;
      background-color: #2d8a25;
      border-color: #2d8a25; }

.ScrollTopButton {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  z-index: -988;
  display: block;
  padding: 0.3rem 0.7rem;
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid #2d8a25;
  opacity: 0;
  transition: all 500ms; }
  .ScrollTopButton.visible {
    z-index: 988;
    opacity: 1; }

.section-header {
  position: relative;
  z-index: 6;
  width: 100%;
  text-align: center; }
  .section-header h2 {
    margin-bottom: 3rem;
    padding: 0 1rem; }
  .section-header h5 {
    margin-bottom: 1rem;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
    font-size: 0.625rem;
    letter-spacing: 5px;
    text-transform: uppercase; }
  .section-header h6 {
    position: absolute;
    top: -3rem;
    right: 0;
    left: 0;
    color: rgba(255, 255, 255, 0.03);
    font-weight: 500;
    font-size: 3rem;
    line-height: 2;
    letter-spacing: 5px;
    text-transform: uppercase; }
    @media (min-width: 700px) {
      .section-header h6 {
        font-size: 3.75rem;
        line-height: 1.5; } }

.logo {
  display: block;
  width: 100%;
  font-size: 3rem;
  text-transform: uppercase; }
  .logo a {
    display: inline-block;
    width: 100%;
    text-indent: -99999rem;
    background: url(/static/media/its-boehm-logo.665664a1.svg) no-repeat center; }

.Modal {
  position: fixed;
  top: 10vh;
  left: 10%;
  z-index: 991;
  width: 80%;
  background: #0d0d0d;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26); }
  @media (min-width: 700px) {
    .Modal {
      top: 15vh;
      left: calc(50% - 20rem);
      width: 40rem; } }
  @media (min-width: 1200px) {
    .Modal {
      top: 20vh;
      left: calc(50% - 30rem);
      width: 60rem; } }
  .Modal__header {
    width: 100%;
    padding: 1rem 0.5rem;
    background: #2d8a25; }
    .Modal__header h2 {
      margin: 0.5rem; }
  .Modal__content {
    max-height: 60vh;
    margin: 0.5rem;
    padding: 1rem 0.5rem;
    overflow-y: auto; }
    .Modal__content:last-child {
      margin-bottom: 0; }
  .Modal-enter {
    transform: translateY(-10rem);
    opacity: 0; }
    .Modal-enter-active {
      transform: translateY(0);
      opacity: 1;
      transition: all 300ms; }
  .Modal-exit {
    transform: translateY(0);
    opacity: 1; }
    .Modal-exit-active {
      transform: translateY(-10rem);
      opacity: 0;
      transition: all 300ms; }
  .Modal .response-modal h4 {
    margin-bottom: 1.5rem; }
  .Modal .response-modal ul {
    margin: 1.5rem 0;
    padding-left: 1rem; }
  .Modal .response-modal li {
    list-style-type: none; }
  .Modal .response-modal svg {
    margin-right: 0.5rem; }

.go-next {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 989;
  width: 32px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 32px;
  color: #fff;
  font-size: 0.75rem;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase; }
  .go-next:hover {
    bottom: -32px; }
  .go-next span:first-of-type {
    white-space: nowrap;
    -webkit-text-orientation: mixed;
            text-orientation: mixed;
    transform: rotate(180deg);
    -webkit-writing-mode: vertical-lr;
        -ms-writing-mode: tb-lr;
            writing-mode: vertical-lr; }
  .go-next span:last-of-type {
    display: block;
    width: 1px;
    height: 40px;
    margin-left: calc(32px / 2);
    background: #fff; }
    .go-next span:last-of-type::after {
      position: absolute;
      bottom: 0;
      left: calc(50% - 4px);
      z-index: 989;
      border-top: 10px #fff solid;
      border-right: 4px transparent solid;
      border-left: 4px transparent solid;
      content: ""; }

.social {
  display: flex;
  justify-content: space-between;
  max-width: 12.5rem;
  margin: 0 auto;
  padding: 0; }
  .social li {
    list-style: none; }
    .social li a {
      color: #fff;
      font-size: 1.5rem; }
      .social li a:hover {
        color: #2d8a25; }

.Spinner {
  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #0d0d0d;
  transition: all 800ms; }
  .Spinner__inner {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 80px;
    transition: width 800ms, height 800ms; }
    .Spinner__inner div {
      position: absolute;
      width: 16px;
      height: 16px;
      background: #2d8a25;
      border-radius: 50%;
      transition: width 800ms, height 800ms;
      -webkit-animation: spinner 1.2s linear infinite;
              animation: spinner 1.2s linear infinite; }
    .Spinner__inner div:nth-child(1) {
      top: 8px;
      left: 8px;
      -webkit-animation-delay: 0s;
              animation-delay: 0s; }
    .Spinner__inner div:nth-child(2) {
      top: 8px;
      left: 32px;
      -webkit-animation-delay: -0.4s;
              animation-delay: -0.4s; }
    .Spinner__inner div:nth-child(3) {
      top: 8px;
      left: 56px;
      -webkit-animation-delay: -0.8s;
              animation-delay: -0.8s; }
    .Spinner__inner div:nth-child(4) {
      top: 32px;
      left: 8px;
      -webkit-animation-delay: -0.4s;
              animation-delay: -0.4s; }
    .Spinner__inner div:nth-child(5) {
      top: 32px;
      left: 32px;
      -webkit-animation-delay: -0.8s;
              animation-delay: -0.8s; }
    .Spinner__inner div:nth-child(6) {
      top: 32px;
      left: 56px;
      -webkit-animation-delay: -1.2s;
              animation-delay: -1.2s; }
    .Spinner__inner div:nth-child(7) {
      top: 56px;
      left: 8px;
      -webkit-animation-delay: -0.8s;
              animation-delay: -0.8s; }
    .Spinner__inner div:nth-child(8) {
      top: 56px;
      left: 32px;
      -webkit-animation-delay: -1.2s;
              animation-delay: -1.2s; }
    .Spinner__inner div:nth-child(9) {
      top: 56px;
      left: 56px;
      -webkit-animation-delay: -1.6s;
              animation-delay: -1.6s; }
  .Spinner.exit-done {
    right: 0;
    width: 0; }
    .Spinner.exit-done .Spinner__inner,
    .Spinner.exit-done .Spinner__inner div {
      width: 0;
      height: 0; }

@-webkit-keyframes spinner {
  0%,
  100% {
    opacity: 1; }
  50% {
    opacity: 0.3; } }

@keyframes spinner {
  0%,
  100% {
    opacity: 1; }
  50% {
    opacity: 0.3; } }

.nav-toggle {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 20px;
  text-decoration: none;
  visibility: visible;
  cursor: pointer;
  opacity: 1;
  transition: 500ms; }
  @media (min-width: 700px) {
    .nav-toggle {
      visibility: hidden; } }
  .nav-toggle:hover i::before {
    top: -10px; }
  .nav-toggle:hover i::after {
    bottom: -10px; }
  .nav-toggle i {
    position: relative;
    display: -moz-inline-stack;
    display: inline-block;
    *display: inline;
    width: 30px;
    height: 2px;
    color: #2d8a25;
    font: bold 14px;
    text-transform: uppercase;
    text-indent: -55px;
    background: #2d8a25;
    transition: all 300ms ease-out;
    zoom: 1; }
    .nav-toggle i::before, .nav-toggle i::after {
      position: absolute;
      left: 0;
      width: 30px;
      height: 2px;
      background: #2d8a25;
      transition: 300ms;
      content: ""; }
    .nav-toggle i::before {
      top: -7px; }
    .nav-toggle i::after {
      bottom: -7px; }
    .offcanvas .nav-toggle i {
      background: transparent; }
      .offcanvas .nav-toggle i::before {
        top: 0;
        transform: rotateZ(45deg); }
      .offcanvas .nav-toggle i::after {
        bottom: 0;
        transform: rotateZ(-45deg); }
  .offcanvas .nav-toggle {
    transform: translateX(270px); }

.Aside {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 901;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 270px;
  padding: 3rem;
  padding-left: 3em;
  background: #000;
  transform: translateX(-270px);
  transition: 500ms; }
  @media (min-width: 700px) {
    .Aside {
      width: calc(100% - 70%);
      transform: translateX(0); } }
  @media (min-width: 900px) {
    .Aside {
      width: calc(100% - 75%);
      padding: 4rem 3rem; } }
  @media (min-width: 1200px) {
    .Aside {
      width: calc(100% - 80%); } }
  @media (min-width: 1600px) {
    .Aside {
      width: calc(100% - 85%); } }
  .offcanvas .Aside {
    position: fixed;
    z-index: 899;
    width: 270px;
    background: #000;
    transform: translateX(0); }
  .Aside .logo {
    margin-bottom: 1rem;
    text-align: right; }
    .Aside .logo a {
      text-indent: 99999rem;
      background-position-x: right; }
  .Aside .main-menu ul {
    margin: 0 0 2rem 0;
    padding: 0;
    text-align: right; }
    @media (min-width: 700px) {
      .Aside .main-menu ul {
        margin: 0; } }
    .Aside .main-menu ul li {
      margin: 0 0 0.625rem 0;
      padding: 0;
      font-weight: 500;
      font-size: 0.75rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      list-style: none; }
      @media (min-width: 700px) {
        .Aside .main-menu ul li {
          font-size: 1rem; } }
      .Aside .main-menu ul li.active a,
      .Aside .main-menu ul li a.active {
        color: #2d8a25; }
        .Aside .main-menu ul li.active a::after,
        .Aside .main-menu ul li a.active::after {
          transform: scaleX(1);
          visibility: visible; }
      .Aside .main-menu ul li a {
        position: relative;
        padding: 10px 0;
        color: #fff;
        text-decoration: none;
        transition: 300ms; }
        .Aside .main-menu ul li a:hover {
          color: #2d8a25; }
          .Aside .main-menu ul li a:hover::after {
            transform: scaleX(1);
            visibility: visible; }
        .Aside .main-menu ul li a::after {
          position: absolute;
          right: 0;
          bottom: 1.06rem;
          left: -1.5rem;
          width: 1rem;
          height: 1px;
          background-color: #2d8a25;
          transform: scaleX(0);
          visibility: hidden;
          transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
          content: ""; }
  .Aside .footer {
    padding: 0;
    color: #fff;
    font-weight: 400;
    text-align: center; }
    .Aside .footer p {
      font-size: 0.6rem; }
      @media (min-width: 700px) {
        .Aside .footer p {
          font-size: 0.7rem; } }

.Hero {
  position: relative;
  float: left;
  clear: both;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  margin-bottom: 2rem; }
  @media (min-width: 700px) {
    .Hero {
      margin-bottom: 5rem; } }
  .Hero::after {
    position: absolute;
    right: 0px;
    bottom: -83px;
    z-index: 2;
    width: 144px;
    height: 166px;
    background-image: url(/static/media/dots-main.9b2592f5.svg);
    background-repeat: no-repeat;
    background-size: 144px 166px;
    content: "";
    opacity: 0.5; }
  .Hero .btn {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    z-index: 25;
    margin: 0 0.25rem 0.25rem 0; }
  .Hero .content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100vh;
    min-height: 400px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed; }
    .no-webp.np-jpeg2000 .Hero .content {
      background-image: url(/static/media/itsb-boehm-alexander-bg.864d25de.jpg); }
      @media only screen and (min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .no-webp.np-jpeg2000 .Hero .content {
          background-image: url(/static/media/itsb-boehm-alexander-bg@2x.b5fb65e1.jpg); } }
    .webp .Hero .content {
      background-image: url(/static/media/itsb-boehm-alexander-bg.b3482931.webp); }
      @media only screen and (min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .webp .Hero .content {
          background-image: url(/static/media/itsb-boehm-alexander-bg@2x.76f076de.webp); } }
    .jpeg-2000 .Hero .content {
      background-image: url(/static/media/itsb-boehm-alexander-bg.f7ef31b4.jp2); }
      @media only screen and (min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .jpeg-2000 .Hero .content {
          background-image: url(/static/media/itsb-boehm-alexander-bg@2x.c8db788f.jp2); } }
    .Hero .content .overlay {
      position: absolute;
      z-index: 5;
      width: 100%;
      height: 100vh;
      background: rgba(0, 0, 0, 0.65); }
    .Hero .content header {
      position: relative;
      z-index: 9;
      margin-top: 12rem;
      padding: 5rem; }
      @media (min-width: 500px) {
        .Hero .content header {
          margin-top: 15rem; } }
      @media (min-width: 900px) {
        .Hero .content header {
          margin-top: 20rem; } }
      @media (min-width: 1200px) {
        .Hero .content header {
          margin-top: 25rem; } }
      .Hero .content header h1 {
        margin-bottom: 0.625rem;
        font-weight: 600;
        font-size: 2rem;
        line-height: 1.3; }
        @media (min-width: 900px) {
          .Hero .content header h1 {
            font-size: 4rem; } }
      .Hero .content header h2 {
        margin-bottom: 2rem;
        color: #2d8a25;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.5;
        text-shadow: 1px 0 1rem #fff; }
        @media (min-width: 900px) {
          .Hero .content header h2 {
            font-size: 2rem; } }

.About .box {
  display: flex;
  flex-basis: 100%;
  flex-direction: column; }
  @media (min-width: 900px) {
    .About .box {
      flex: 1 1; } }
  .About .box--image {
    margin-bottom: 3rem; }
    @media (min-width: 900px) {
      .About .box--image {
        margin-bottom: 0; } }
    .About .box--image .design-bg {
      position: relative;
      width: 65%;
      height: 550px;
      border: 1px solid rgba(45, 138, 37, 0.1); }
      .About .box--image .design-bg::after {
        position: absolute;
        left: 35px;
        bottom: 15px;
        z-index: -2;
        width: 144px;
        height: 166px;
        background-image: url(/static/media/dots-secondary.b9009047.svg);
        background-repeat: no-repeat;
        background-size: 144px 166px;
        content: ""; }
      .About .box--image .design-bg,
      .About .box--image .design-bg .img {
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover; }
      .About .box--image .design-bg .img {
        position: absolute;
        top: 85px;
        right: -110px;
        width: 100%;
        height: 390px;
        border-radius: 4px;
        transition: top 800ms ease-in-out; }
        .no-webp.np-jpeg2000 .About .box--image .design-bg .img {
          background-image: url(/static/media/itsb-boehm-alexander-portrait.c7499c04.jpg); }
          @media only screen and (min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
            .no-webp.np-jpeg2000 .About .box--image .design-bg .img {
              background-image: url(/static/media/itsb-boehm-alexander-portrait@2x.c4a46dfe.jpg); } }
        .webp .About .box--image .design-bg .img {
          background-image: url(/static/media/itsb-boehm-alexander-portrait.00143c55.webp); }
          @media only screen and (min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
            .webp .About .box--image .design-bg .img {
              background-image: url(/static/media/itsb-boehm-alexander-portrait@2x.b8e47df5.webp); } }
        .jpeg-2000 .About .box--image .design-bg .img {
          background-image: url(/static/media/itsb-boehm-alexander-portrait.e5be4d40.jp2); }
          @media only screen and (min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
            .jpeg-2000 .About .box--image .design-bg .img {
              background-image: url(/static/media/itsb-boehm-alexander-portrait@2x.3ac11a7f.jp2); } }
        .About .box--image .design-bg .img:hover {
          top: 50px; }
      .About .box--image .design-bg-enter {
        -webkit-animation-name: fadeInLeft;
                animation-name: fadeInLeft;
        -webkit-animation-duration: 800ms;
                animation-duration: 800ms; }
      .About .box--image .design-bg-exit {
        -webkit-animation-name: fadeOut;
                animation-name: fadeOut;
        -webkit-animation-duration: 500ms;
                animation-duration: 500ms; }
  @media (min-width: 900px) {
    .About .box--description .section-header {
      text-align: inherit; }
      .About .box--description .section-header h4 {
        left: -1rem; } }
  .About .box--description .inner-box-enter {
    -webkit-animation-name: fadeInRight;
            animation-name: fadeInRight;
    -webkit-animation-duration: 800ms;
            animation-duration: 800ms; }
  .About .box--description .inner-box-exit {
    -webkit-animation-name: fadeOut;
            animation-name: fadeOut;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms; }
  .About .box--description .content {
    padding: 0 1rem; }
    .About .box--description .content .description h4 strong {
      text-shadow: #2d8a25 0 0 7px; }
    .About .box--description .content .description p:first-of-type {
      margin-bottom: 0.5rem; }
    .About .box--description .content .description,
    .About .box--description .content .contact {
      margin-bottom: 1.5rem;
      border-bottom: 1px solid rgba(45, 138, 37, 0.2); }
    .About .box--description .content .contact {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 1.5rem; }
      .About .box--description .content .contact .info {
        display: flex;
        flex-basis: 100%;
        margin-bottom: 1rem; }
        @media (min-width: 500px) {
          .About .box--description .content .contact .info {
            flex-basis: 50%; } }
        @media (min-width: 900px) {
          .About .box--description .content .contact .info {
            flex-basis: 100%; } }
        @media (min-width: 1200px) {
          .About .box--description .content .contact .info {
            flex-basis: 50%; } }
        .About .box--description .content .contact .info span {
          display: inline-block; }
          .About .box--description .content .contact .info span:first-child {
            width: 5rem; }
    .About .box--description .content .actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around; }
      .About .box--description .content .actions .btn {
        width: 100%;
        margin: 0.5rem 0; }
        @media (min-width: 500px) {
          .About .box--description .content .actions .btn {
            width: inherit;
            margin: 0 0.5rem; } }
        @media (min-width: 900px) {
          .About .box--description .content .actions .btn {
            width: 100%;
            margin: 0.5rem 0; } }
        @media (min-width: 1200px) {
          .About .box--description .content .actions .btn {
            width: inherit;
            margin: 0 0.5rem; } }

.Services {
  background: #070707; }
  .Services .content {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
  .Services .Service {
    position: relative;
    flex-basis: 100%;
    margin-bottom: 2.5rem;
    padding: 1.5rem 0;
    border: 1px solid rgba(45, 138, 37, 0.1);
    border-radius: 3px;
    transition: all 800ms ease-out; }
    @media (min-width: 900px) {
      .Services .Service {
        flex-basis: calc(50% - 1rem); }
        .Services .Service:nth-of-type(odd) {
          margin-right: 2rem; } }
    @media (min-width: 1600px) {
      .Services .Service {
        flex-basis: calc(25% - 1.6rem); }
        .Services .Service:not(:last-of-type) {
          margin-right: 2rem; } }
    .Services .Service:hover {
      background-color: #000;
      border-color: #2d8a25; }
    .Services .Service .icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 6.25rem; }
      .Services .Service .icon svg {
        display: block;
        height: 6.25rem;
        margin: auto;
        color: #2d8a25;
        font-size: 2.5rem; }
    .Services .Service .num {
      position: absolute;
      top: 60%;
      left: 2rem;
      color: rgba(255, 255, 255, 0.1);
      font-weight: 700;
      font-size: 1.875rem;
      transform: translateY(-50%); }
      @media (min-width: 900px) {
        .Services .Service .num {
          top: 50%; } }
    .Services .Service .text {
      width: 100%;
      padding-right: 2rem;
      padding-left: 6rem; }
      .Services .Service .text h3 {
        margin: 0 0 1.25rem;
        padding: 0;
        color: #2d8a25;
        font-weight: 500;
        font-size: 0.875rem;
        letter-spacing: 3px;
        text-transform: uppercase; }
      .Services .Service .text p {
        margin-bottom: 0; }

.Testimonials {
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  background-attachment: fixed; }
  .no-webp.np-jpeg2000 .Testimonials {
    background-image: url(/static/media/itsb-testimonials-bg.049c446e.jpg); }
    @media only screen and (min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .no-webp.np-jpeg2000 .Testimonials {
        background-image: url(/static/media/itsb-testimonials-bg@2x.e26bfde1.jpg); } }
  .webp .Testimonials {
    background-image: url(/static/media/itsb-testimonials-bg.d33eda33.webp); }
    @media only screen and (min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .webp .Testimonials {
        background-image: url(/static/media/itsb-testimonials-bg@2x.48a17879.webp); } }
  .jpeg-2000 .Testimonials {
    background-image: url(/static/media/itsb-testimonials-bg.e500a7e1.jp2); }
    @media only screen and (min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .jpeg-2000 .Testimonials {
        background-image: url(/static/media/itsb-testimonials-bg@2x.d6770438.jp2); } }
  .Testimonials .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); }
  .Testimonials .section-header h6 {
    color: rgba(255, 255, 255, 0.1); }
  .Testimonials .slider {
    z-index: 6;
    margin-bottom: 1.5rem;
    font-style: italic;
    text-align: center; }
    .Testimonials .slider-list {
      cursor: -webkit-grab !important;
      cursor: grab !important; }
    .Testimonials .slider-slide img {
      width: 6.5rem;
      margin: 0 auto 2rem;
      border-radius: 6.5rem; }
    .Testimonials .slider-slide .quote {
      max-width: 40rem;
      margin: auto;
      padding-bottom: 1rem; }
      .Testimonials .slider-slide .quote span {
        font-size: 1.5rem;
        line-height: 0; }
    .Testimonials .slider-slide .name,
    .Testimonials .slider-slide .company {
      display: block; }
    .Testimonials .slider-slide .company {
      font-size: 0.75rem; }

@media (min-width: 1200px) {
  .Stats {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto; } }

.Stats .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.5rem; }
  @media (min-width: 1200px) {
    .Stats .content {
      flex-direction: row;
      justify-content: space-around; } }

.Stats .stat-item {
  text-align: center; }
  .Stats .stat-item:not(:last-of-type) {
    border-bottom: 1px solid rgba(45, 138, 37, 0.4); }
    @media (min-width: 1200px) {
      .Stats .stat-item:not(:last-of-type) {
        border: 0; } }
  .Stats .stat-item .counter {
    font-size: 2.5rem; }
  .Stats .stat-item .upper-title,
  .Stats .stat-item .lower-title {
    max-width: 10rem;
    margin: auto;
    font-size: 0.875rem;
    letter-spacing: 4px;
    text-transform: uppercase; }
  .Stats .stat-item .upper-title {
    margin-top: 1rem; }
  .Stats .stat-item .lower-title {
    margin-bottom: 1rem; }

.Contact::after {
  position: absolute;
  left: 0px;
  bottom: -83px;
  z-index: 2;
  width: 144px;
  height: 166px;
  background-image: url(/static/media/dots-main.9b2592f5.svg);
  background-repeat: no-repeat;
  background-size: 144px 166px;
  content: "";
  opacity: 0.2; }

@media (min-width: 1200px) {
  .Contact {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto; } }

@media (min-width: 1600px) {
  .Contact::after {
    left: -12.5rem; } }

.Contact .details {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }

.Contact .contact-detail {
  flex-basis: 100%;
  margin: 0 1.5rem;
  text-align: center; }
  .Contact .contact-detail:not(:last-of-type) {
    padding-bottom: 2.5rem;
    border-bottom: 1px solid rgba(45, 138, 37, 0.2); }
  .Contact .contact-detail:not(:first-of-type) {
    padding-top: 2rem; }
  @media (min-width: 900px) {
    .Contact .contact-detail {
      flex: 1 1;
      margin: inherit; }
      .Contact .contact-detail:not(:last-of-type) {
        padding-bottom: inherit;
        border: inherit; }
      .Contact .contact-detail:not(:first-of-type) {
        padding-top: inherit; } }

.Contact .icon {
  color: #2d8a25;
  font-size: 2.5rem; }

.Contact .content h4 {
  margin-bottom: 1.25rem;
  color: #2d8a25;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 3px;
  text-transform: uppercase; }

.Contact .content p {
  max-width: 15rem;
  margin: 0 auto 1rem;
  font-weight: 500;
  font-size: 0.875rem; }

.Contact form {
  display: block;
  width: 100%;
  margin-top: 6rem; }
  .Contact form input[type="text"],
  .Contact form input[type="email"],
  .Contact form input[type="phone"],
  .Contact form textarea {
    width: 100%;
    margin-bottom: 1.5rem;
    padding: 0.5rem 1.25rem;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4;
    background: transparent;
    background-image: none;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0;
    box-shadow: none;
    transition: all ease-in-out 800ms;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
    .Contact form input[type="text"]:active, .Contact form input[type="text"]:focus,
    .Contact form input[type="email"]:active,
    .Contact form input[type="email"]:focus,
    .Contact form input[type="phone"]:active,
    .Contact form input[type="phone"]:focus,
    .Contact form textarea:active,
    .Contact form textarea:focus {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 2px solid #2d8a25;
      outline: none;
      box-shadow: none; }
    .Contact form input[type="text"].error,
    .Contact form input[type="email"].error,
    .Contact form input[type="phone"].error,
    .Contact form textarea.error {
      border-bottom: 2px solid #bb0000; }
  .Contact form input[type="text"],
  .Contact form input[type="email"],
  .Contact form input[type="phone"] {
    height: 3rem; }
  .Contact form textarea {
    height: 10rem; }
  .Contact form .error-message {
    margin-top: -1rem;
    padding-left: 1.2rem;
    color: #ff3c3c;
    font-style: italic; }
  .Contact form .switch {
    display: flex;
    flex-direction: column;
    text-align: center; }
    @media (min-width: 700px) {
      .Contact form .switch {
        flex-direction: row;
        text-align: inherit; } }
    .Contact form .switch input[type="checkbox"] {
      position: relative;
      display: block;
      width: 2.2rem;
      height: calc(2.2rem / 2);
      margin: 0 auto 1rem;
      background-color: #4d4d4d;
      border-radius: calc(2.2rem / 2);
      outline: none;
      box-shadow: 0 0 0 3px #393939;
      transition: background-color 500ms ease;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none; }
      @media (min-width: 700px) {
        .Contact form .switch input[type="checkbox"] {
          margin: 0.25rem 2rem 0; } }
      .Contact form .switch input[type="checkbox"]:before, .Contact form .switch input[type="checkbox"]:after {
        position: absolute;
        display: block;
        border-radius: 100%;
        transition: background-color 500ms ease, transform 500ms ease;
        content: ""; }
      .Contact form .switch input[type="checkbox"]:before {
        width: calc(2.2rem / 2);
        height: calc(2.2rem / 2);
        background-color: #1a1a1a; }
      .Contact form .switch input[type="checkbox"]:checked:before {
        background-color: #194e15;
        transform: translateX(100%); }
    .Contact form .switch__text {
      margin-bottom: 1rem; }
  .Contact form input[type="submit"] {
    display: block;
    width: 100%;
    cursor: pointer; }
    @media (min-width: 500px) {
      .Contact form input[type="submit"] {
        width: auto;
        margin-left: auto; } }
    .Contact form input[type="submit"]:disabled {
      color: rgba(255, 255, 255, 0.3);
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.3);
      cursor: default; }

.Footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2.75rem;
  text-align: center;
  background-color: #070707; }
  @media (min-width: 1200px) {
    .Footer {
      flex-direction: row-reverse;
      padding-right: 10rem;
      padding-left: 10rem; } }
  @media (min-width: 1600px) {
    .Footer {
      padding-right: 15rem;
      padding-left: 15rem; } }
  .Footer .logo,
  .Footer .social,
  .Footer .copyright {
    width: 100%; }
    @media (min-width: 1200px) {
      .Footer .logo,
      .Footer .social,
      .Footer .copyright {
        width: 33%; } }
  .Footer .copyright {
    margin: 1rem 0 0; }
  .Footer .copyright {
    font-size: 0.75rem; }

.impress {
  padding: 3rem 1rem 2rem; }
  @media (min-width: 1200px) {
    .impress {
      max-width: 75rem;
      margin-right: auto;
      margin-left: auto; } }
  .impress .row {
    display: flex;
    flex-direction: column; }
    @media (min-width: 700px) {
      .impress .row {
        flex-direction: row; } }
  @media (min-width: 700px) {
    .impress .column {
      width: 50%; } }

.disclaimer {
  padding: 0 1rem 2rem; }
  .disclaimer h4 {
    margin-bottom: 1rem; }

@media (min-width: 1200px) {
  .impress,
  .disclaimer {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto; } }

.Privacy {
  padding: 3rem 1rem 2rem; }
  @media (min-width: 1200px) {
    .Privacy {
      max-width: 75rem;
      margin-right: auto;
      margin-left: auto; } }
  .Privacy ol,
  .Privacy ul {
    padding: 0 1rem; }
    .Privacy ol ol,
    .Privacy ol ul,
    .Privacy ul ol,
    .Privacy ul ul {
      margin-bottom: 1.5rem; }

